import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import $ from "jquery";
import ContactModal from "../model/ContactModal";
// import { MetaTags } from "react-meta-tags";
function Navbar() {
  const [toggle, setToggle] = useState(false);
  const [commonModal, setCommonModal] = useState(false);

  useEffect(() => {
    $(document).ready(function () {
      var s = $("header");
      var pos = s.position();
      $(window).scroll(function () {
        var windowpos = $(window).scrollTop();
        var length = $(".dark").length;
        var vars = {};
        for (var i = 1; i <= length; i++) {
          var j = i - 1;
          vars["dark" + i] = $(".dark").eq(j).offset().top;
          vars["height" + i] = $(".dark").eq(j).innerHeight();
          vars["requiredValue" + i] =
            $(".dark").eq(j).offset().top + vars["height" + i];
        }
        if (
          (windowpos >= pos.top) &
            (windowpos >= vars["dark1"]) &
            (windowpos <= vars["requiredValue1"]) ||
          (windowpos >= pos.top) &
            (windowpos >= vars["dark2"]) &
            (windowpos <= vars["requiredValue2"])
          //   ||
          // (windowpos >= pos.top) &
          //   (windowpos >= vars["dark3"]) &
          //   (windowpos <= vars["requiredValue3"])
          //   ||
          // (windowpos >= pos.top) &
          //   (windowpos >= vars["dark4"]) &
          //   (windowpos <= vars["requiredValue4"])
        ) {
          s.addClass("head-dark");
        } else {
          s.removeClass("head-dark");
        }
      });
    });
  }, []);

  const handleMenuClick = () => {
    setToggle(!toggle);
    document.body.classList.add("no-scroll");
  };

  if (!toggle) {
    document.body.classList.remove("no-scroll");
  }

  return (
    <>
      <header className={`nav-down head-dark`} id="_nav">
        <div className="header-inner">
          <NavLink to="/" className="logo">
            <img src="/assets/images/logo-dark.svg" alt="Navimg" />
          </NavLink>

          <div
            onClick={() => setCommonModal(true)}
            className="sap-btn-light header-btn"
          >
            <button
              data-bs-toggle="modal"
              data-bs-target="#header-contact"
              aria-label="Call-now"
            >
              call now
            </button>
          </div>

          <div className="header-menu">
            <div
              className={`sap-nav-button ${toggle ? `-menu-open` : ``}`}
              onClick={() => handleMenuClick()}
              id="sap-nav-button"
            >
              <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1">
                <g>
                  <line x1="0" y1="17" x2="48" y2="17" strokeWidth="3" />
                  <line x1="0" y1="31" x2="48" y2="31" strokeWidth="3" />
                </g>

                <g>
                  <line x1="0" y1="24" x2="48" y2="24" strokeWidth="3" />
                  <line x1="0" y1="24" x2="48" y2="24" strokeWidth="3" />
                </g>
              </svg>
            </div>

            <nav className={`menu ${toggle ? `-open` : ``}`}>
              <div className="menu-inner">
                <div className="menu-services" id="menu-services1">
                  <div className="sap-sm-heading">Services</div>
                  <div className="sap-menu-card">
                    <div className="menu-services-inner" id="design">
                      <div className="menu-sm-heading">
                        <Link
                          to="/design"
                          onClick={() => {
                            setToggle(!toggle);
                            window.scroll({
                              top: 0,
                              behavior: "instant",
                            });
                          }}
                          aria-label="Visit Design Page"
                        >
                          Design
                        </Link>
                      </div>
                      <div className="msi-head">
                        <ul>
                          <li>

                            <Link
                              to="/design/website-design"
                              onClick={() => {
                                setToggle(!toggle);
                              }}
                            >
                              Website Design
                              {/* <MetaTags>
                                <meta
                                  id="meta-description"
                                  name="description"
                                  content="Boost your online presence with our website design services. Our expert team creates beautiful and user-friendly websites tailored to your brand needs."
                                />
                              </MetaTags> */}
                            </Link>
                            
                          </li>
                          <li>
                            <Link
                              to="/design/desktop-design"
                              onClick={() => {
                                setToggle(!toggle);
                              }}
                            >
                              Desktop Design
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/design/mobile-application-design"
                              onClick={() => {
                                setToggle(!toggle);
                              }}
                            >
                              Mobile App Design
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/design/desktop-design/Marketing-design"
                              onClick={() => {
                                setToggle(!toggle);
                              }}
                            >
                              Marketing Design
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="menu-services-inner" id="development">
                      <div className="menu-sm-heading">
                        <Link
                          to="/development"
                          onClick={() => {
                            setToggle(!toggle);
                            window.scroll({
                              top: 0,
                              behavior: "instant",
                            });
                          }}
                          aria-label="Visit Development Page"
                        >
                          Development
                        </Link>
                      </div>
                      <div className="msi-head">
                        <div className="msi-inner">
                          <ul>
                            <li>
                              <Link
                                to="/development/website-development"
                                onClick={() => setToggle(!toggle)}
                              >
                                Website Development
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/development/web-app-development"
                                onClick={() => setToggle(!toggle)}
                              >
                                Web App Development
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/development/mobile-app-development"
                                onClick={() => setToggle(!toggle)}
                              >
                                Mobile App Development
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/development/desktop-app-development"
                                onClick={() => setToggle(!toggle)}
                              >
                                Desktop App Development
                              </Link>
                            </li>
                            <li className="slashed-link">
                              <Link
                                to="/development/customer-relationship-management"
                                onClick={() => setToggle(!toggle)}
                              >
                                CRM
                              </Link>
                              <span className="divider">/</span>
                              <Link
                                to="/development/content-management-system"
                                onClick={() => setToggle(!toggle)}
                              >
                                CMS
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="menu-services-inner" id="marketing">
                      <div className="menu-sm-heading">
                        <Link
                          to="/digital-marketing"
                          onClick={() => {
                            setToggle(!toggle);
                            window.scroll({
                              top: 0,
                              behavior: "instant",
                            });
                          }}
                          aria-label="Visit Digital Page"
                        >
                          Marketing
                        </Link>
                      </div>
                      <div className="msi-head">
                        <div className="msi-inner">
                          <ul>
                            <li>
                              <Link
                                to="/digital-marketing/search-engine-optimization"
                                onClick={() => setToggle(!toggle)}
                              >
                                Search Engine Optimization
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/digital-marketing/social-media-management"
                                onClick={() => setToggle(!toggle)}
                              >
                                Social Media Management
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/digital-marketing/paid-marketing"
                                onClick={() => setToggle(!toggle)}
                              >
                                Paid Marketing
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/digital-marketing/app-store-optimization"
                                onClick={() => setToggle(!toggle)}
                              >
                                Mobile App Marketing
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="msi-inner">
                          <ul>
                            <li>
                              <Link
                                to="/digital-marketing/content-marketing"
                                onClick={() => setToggle(!toggle)}
                              >
                                Content Marketing
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/digital-marketing/email-marketing"
                                onClick={() => setToggle(!toggle)}
                              >
                                E-Mail Marketing
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/digital-marketing/digital-branding"
                                onClick={() => setToggle(!toggle)}
                              >
                                Digital Branding
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/digital-marketing/analytics-and-reporting"
                                onClick={() => setToggle(!toggle)}
                              >
                                Analytics And Reporting
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="menu-services-inner" id="Cloud_Computing">
                      <div className="menu-sm-heading">
                        <Link
                          to="/cloud-computing"
                          onClick={() => {
                            setToggle(!toggle);
                            window.scroll({
                              top: 0,
                              behavior: "instant",
                            });
                          }}
                          aria-label="Visit Cloud Page"
                        >
                          Cloud Computing
                        </Link>
                      </div>
                      <div className="msi-head">
                        <div className="msi-inner">
                          <ul>
                            <li>
                              <Link
                                to="/cloud-computing/cloud-infrastructure"
                                onClick={() => setToggle(!toggle)}
                              >
                                Cloud Infrastructure
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/cloud-computing/server-management"
                                onClick={() => setToggle(!toggle)}
                              >
                                Server Management
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/cloud-computing/server-management-kindlebit"
                                onClick={() => setToggle(!toggle)}
                              >
                                Server management with Kindlebit
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/cloud-computing/database-management"
                                onClick={() => setToggle(!toggle)}
                              >
                                Database Management
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="msi-inner">
                          <ul>
                            <li>
                              <Link
                                to="/cloud-computing/iaas"
                                onClick={() => setToggle(!toggle)}
                              >
                                IaaS
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/cloud-computing/paas"
                                onClick={() => setToggle(!toggle)}
                              >
                                PaaS
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/cloud-computing/saas"
                                onClick={() => setToggle(!toggle)}
                              >
                                SaaS
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="menu-services-inner" id="Blockchain">
                      <div className="menu-sm-heading">
                        <Link
                          to="/web-3"
                          onClick={() => {
                            setToggle(!toggle);
                            window.scroll({
                              top: 0,
                              behavior: "instant",
                            });
                          }}
                          aria-label="Visit Web Page"
                        >
                          Web 3
                        </Link>
                      </div>
                      <div className="msi-head">
                        <div className="msi-inner">
                          <ul>
                            <li>
                              <Link
                                to="/web-3/blockchain-development"
                                onClick={() => setToggle(!toggle)}
                              >
                                Blockchain Development
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/web-3/smart-contract"
                                onClick={() => setToggle(!toggle)}
                              >
                                Smart Contract
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/web-3/crypto-wallet"
                                onClick={() => setToggle(!toggle)}
                              >
                                Crypto Wallet
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/web-3/tokenization"
                                onClick={() => setToggle(!toggle)}
                              >
                                Tokenization
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/web-3/marketplace-development"
                                onClick={() => setToggle(!toggle)}
                              >
                                Marketplace Development
                              </Link>
                            </li>
                            {/* <li>
                              <Link
                                to="/web-3/trading-bot"
                                onClick={() => setToggle(!toggle)}
                              >
                                Trading Bot
                              </Link>
                            </li> */}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="menu-services" id="menu-services2">
                  <div className="sap-sm-heading">Technologies</div>
                  <div className="sap-menu-card">
                    <div className="ms-left">
                      <div className="menu-sm-heading">
                        <Link
                          to="/web"
                          style={{ textDecoration: "none" }}
                          onClick={() => {
                            setToggle(!toggle);
                            window.scroll({
                              top: 0,
                              behavior: "instant",
                            });
                          }}
                          aria-label="Web"
                        >
                          Web
                        </Link>
                      </div>

                      <div className="menu-services-head">
                        <div className="menu-services-inner">
                          <div className="menu-sm-white">
                            <Link
                              to="/web/frontend"
                              onClick={() => setToggle(!toggle)}
                            >
                              Frontend
                            </Link>
                          </div>
                          <div className="msi-head">
                            <div className="msi-inner">
                              <ul>
                                <li>
                                  <Link
                                    to="/web/frontend/angular-js"
                                    onClick={() => setToggle(!toggle)}
                                  >
                                    Angular JS
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/web/frontend/react-js"
                                    onClick={() => setToggle(!toggle)}
                                  >
                                    React JS
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/web/frontend/vue-js"
                                    onClick={() => setToggle(!toggle)}
                                  >
                                    Vue JS
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <div className="menu-services-inner">
                          <div className="menu-sm-white">
                            <Link
                              to="/web/backend"
                              onClick={() => setToggle(!toggle)}
                            >
                              Backend
                            </Link>
                          </div>
                          <div className="msi-head">
                            <div className="msi-inner">
                              <ul>
                                <li>
                                  <Link
                                    to="/web/backend/node-js"
                                    onClick={() => setToggle(!toggle)}
                                  >
                                    Node JS
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/web/backend/php"
                                    onClick={() => setToggle(!toggle)}
                                  >
                                    PHP
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/web/backend/ruby-on-rails"
                                    onClick={() => setToggle(!toggle)}
                                  >
                                    Ruby on Rails
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/web/backend/java"
                                    onClick={() => setToggle(!toggle)}
                                  >
                                    Java
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/web/backend/python"
                                    onClick={() => setToggle(!toggle)}
                                  >
                                    Python
                                  </Link>
                                </li>
                              </ul>
                            </div>
                            <div className="msi-inner">
                              <ul>
                                <li>
                                  <Link
                                    to="/web/backend/.net"
                                    onClick={() => setToggle(!toggle)}
                                  >
                                    .NET
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/web/backend/golang"
                                    onClick={() => setToggle(!toggle)}
                                  >
                                    Golang
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/web/backend/magento"
                                    onClick={() => setToggle(!toggle)}
                                  >
                                    Magneto
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/web/backend/laravel"
                                    onClick={() => setToggle(!toggle)}
                                  >
                                    Laravel
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <div className="menu-services-inner">
                          <div className="menu-sm-white">
                            <Link
                              to="/web/others"
                              onClick={() => setToggle(!toggle)}
                            >
                              Others
                            </Link>
                          </div>
                          <div className="msi-head">
                            <div className="msi-inner">
                              <ul>
                                <li>
                                  <Link
                                    to="/web/others/mean-stack"
                                    onClick={() => setToggle(!toggle)}
                                  >
                                    MEAN Stack
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/web/others/mern-stack"
                                    onClick={() => setToggle(!toggle)}
                                  >
                                    MERN Stack
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/web/others/mevn-stack"
                                    onClick={() => setToggle(!toggle)}
                                  >
                                    MEVN Stack
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/web/others/devops-azure"
                                    onClick={() => setToggle(!toggle)}
                                  >
                                    Devops - Azure
                                  </Link>
                                </li>
                              </ul>
                            </div>
                            <div className="msi-inner">
                              <ul>
                                <li>
                                  <Link
                                    to="/web/others/devops-aws"
                                    onClick={() => setToggle(!toggle)}
                                  >
                                    Devops - AWS
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/web/others/testing"
                                    onClick={() => setToggle(!toggle)}
                                  >
                                    Testing
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/web/others/c-mvc"
                                    onClick={() => setToggle(!toggle)}
                                  >
                                    C# / MVC
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-right">
                      <div className="menu-sm-heading">
                        <Link
                          to="/mobile"
                          style={{ textDecoration: "none", color: "white" }}
                          onClick={() => {
                            setToggle(!toggle);
                            window.scroll({
                              top: 0,
                              behavior: "instant",
                            });
                          }}
                        >
                          Mobile
                        </Link>
                      </div>
                      <div className="menu-services-head">
                        <div className="menu-services-inner">
                          <div className="menu-sm-white">
                            <Link
                              to="/mobile/hybrid-development/flutter"
                              onClick={() => setToggle(!toggle)}
                            >
                              Hybrid Development
                            </Link>
                          </div>
                          <div className="msi-head">
                            <div className="msi-inner">
                              <ul>
                                <li>
                                  <Link
                                    to="/mobile/hybrid-development/flutter"
                                    onClick={() => setToggle(!toggle)}
                                  >
                                    Flutter
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/mobile/hybrid-development/flutter-flow"
                                    onClick={() => setToggle(!toggle)}
                                  >
                                    Flutter Flow
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/mobile/hybrid-development/react-native"
                                    onClick={() => setToggle(!toggle)}
                                  >
                                    React Native
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/mobile/hybrid-development/ionic"
                                    onClick={() => setToggle(!toggle)}
                                  >
                                    Ionic
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <div className="menu-services-inner">
                          <div className="menu-sm-white">
                            <Link
                              to="/mobile/native-development/swift-ui"
                              onClick={() => setToggle(!toggle)}
                            >
                              Native Development
                            </Link>
                          </div>
                          <div className="msi-head">
                            <div className="msi-inner">
                              <ul>
                                <li>
                                  <Link
                                    to="/mobile/native-development/swift-ui"
                                    onClick={() => setToggle(!toggle)}
                                  >
                                    Swift UI
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/mobile/native-development/swift"
                                    onClick={() => setToggle(!toggle)}
                                  >
                                    Swift
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/mobile/native-development/kotlin"
                                    onClick={() => setToggle(!toggle)}
                                  >
                                    Kotlin
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/mobile/native-development/objective-c"
                                    onClick={() => setToggle(!toggle)}
                                  >
                                    Objective C
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="menu-other">
                  <ul>
                    <li>
                      <Link
                        to="/about-us"
                        className="sap-sm-heading"
                        onClick={() => {
                          setToggle(!toggle);
                          window.scroll({
                            top: 0,
                            behavior: "instant",
                          });
                        }}
                      >
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/case-study"
                        className="sap-sm-heading"
                        onClick={() => {
                          setToggle(!toggle);
                          window.scroll({
                            top: 0,
                            behavior: "instant",
                          });
                        }}
                      >
                        Case Studies
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/portfolio"
                        className="sap-sm-heading"
                        onClick={() => {
                          setToggle(!toggle);
                          window.scroll({
                            top: 0,
                            behavior: "instant",
                          });
                        }}
                      >
                        Portfolio
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/blog"
                        className="sap-sm-heading"
                        onClick={() => {
                          setToggle(!toggle);
                          window.scroll({
                            top: 0,
                            behavior: "instant",
                          });
                        }}
                      >
                        Blog
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/career"
                        className="sap-sm-heading"
                        onClick={() => {
                          setToggle(!toggle);
                          window.scroll({
                            top: 0,
                            behavior: "instant",
                          });
                        }}
                      >
                        Career
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/contact-us"
                        className="sap-sm-heading"
                        onClick={() => {
                          setToggle(!toggle);
                          window.scroll({
                            top: 0,
                            behavior: "instant",
                          });
                        }}
                      >
                        Contact
                      </Link>
                    </li>
                  </ul>
                </div>
                <ul className="menu-social-links">
                  <li>
                    <a
                      href="https://www.facebook.com/KindleBit"
                      rel="noreferrer"
                      target="_blank"
                      aria-label="Facebook"
                      onClick={() => setToggle(!toggle)}
                    >
                      <i className="fa-brands fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/KindleBit"
                      rel="noreferrer"
                      target="_blank"
                      aria-label="Twitter"
                      onClick={() => setToggle(!toggle)}
                    >
                      <i className="fa-brands fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://instagram.com/kindlebit_solutions"
                      onClick={() => setToggle(!toggle)}
                      rel="noreferrer"
                      target="_blank"
                      aria-label="Instagram"
                    >
                      <i className="fa-brands fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://in.linkedin.com/company/kindlebit-solutions"
                      rel="noreferrer"
                      target="_blank"
                      onClick={() => setToggle(!toggle)}
                      aria-label="LinkedIn"
                    >
                      <i className="fa-brands fa-linkedin-in"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </header>

      {/* Contact modal */}
      {commonModal && (
        <ContactModal
          commonModal={commonModal}
          setCommonModal={setCommonModal}
        />
      )}
    </>
  );
}

export default Navbar;
